import ChevronLeftIcon from "@heroicons/react/16/solid/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/16/solid/ChevronRightIcon";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import DLetter from "../../../assets/svg/hiredly-logo/d-letter.svg";
import ELetter from "../../../assets/svg/hiredly-logo/e-letter.svg";
import HLetter from "../../../assets/svg/hiredly-logo/h-letter.svg";
import ILetter from "../../../assets/svg/hiredly-logo/i-letter.svg";
import LLetter from "../../../assets/svg/hiredly-logo/l-letter.svg";
import RLetter from "../../../assets/svg/hiredly-logo/r-letter.svg";
import YLetter from "../../../assets/svg/hiredly-logo/y-letter.svg";

export const Wrapper = styling("div")({
  display: "flex",
  justifyContent: "center",

  height: "64px",
  width: "100%",
  margin: "1.5rem 0 0",
});

export const PageItemContainer = styling("ul")({
  display: "flex",
  alignItems: "flex-start",
  gap: "0.75rem",

  margin: 0,
  padding: 0,

  "@media (max-width: 640px)": {
    gap: "0.5rem",
  },
});

export const PageItem = styling("li")({
  listStyle: "none",
  padding: 0,
  margin: 0,
});

export const TextPageColor = styling("span")((props) => ({
  color: Color.buttonGreen,
  fontWeight: "700",
  transition: "padding 0.125s ease-in-out",

  ...(!props.isActive && {
    color: Color.secondaryGrey,
    fontWeight: "400",
  }),
}));

export const PageButton = styling(ButtonBase)((props) => ({
  color: "rgba(129, 62, 185, 1)",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",

  fontSize: "0.875rem",
  lineHeight: "20px",
  letterSpacing: "0.125px",

  width: "1.125rem",
  height: "100%",

  ...(!props.isActive && {
    color: Color.secondaryGrey,
  }),

  "&:hover": {
    transition: "color 0.125s ease-in-out, font-weight 0.125s ease-in-out",
    color: Color.hiredlyPurple,

    "& > span": {
      color: Color.buttonGreen,
      padding: "0.5rem 0 0",
      fontWeight: "700",
    },
  },

  ":disabled": {
    pointerEvents: "none",
    opacity: "0.5",
  },
}));

export const NavButton = styling(ButtonBase)({
  color: Color.secondaryGrey,
  borderRadius: "0.25rem",

  transition: "all 0.15s ease-in-out",

  height: "100%",
  padding: "0.5rem",
  margin: "-0.5rem 0 0",

  "&:hover": {
    color: Color.darkGrey,
  },

  ":disabled": {
    pointerEvents: "none",
    opacity: "0.5",
  },

  "@media (max-width: 640px)": {
    height: "16px",
    padding: "0",
    margin: "-0.625rem 0 0",
  },
});

export const PreviousIcon = styling(ChevronLeftIcon)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const NextIcon = styling(ChevronRightIcon)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const HLetterIcon = styling(HLetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const ILetterIcon = styling(ILetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const RLetterIcon = styling(RLetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const ELetterIcon = styling(ELetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const DLetterIcon = styling(DLetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const LLetterIcon = styling(LLetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});

export const YLetterIcon = styling(YLetter)({
  width: "24px",
  height: "24px",

  "@media (max-width: 640px)": {
    width: "16px",
    height: "16px",
  },
});
