import { useIsClient } from "@uidotdev/usehooks";
import { useState } from "react";
import {
  DLetterIcon,
  ELetterIcon,
  HLetterIcon,
  ILetterIcon,
  LLetterIcon,
  NavButton,
  NextIcon,
  PageButton,
  PageItem,
  PageItemContainer,
  PreviousIcon,
  RLetterIcon,
  TextPageColor,
  Wrapper,
  YLetterIcon,
} from "./styles";

export default function SharedPagination(props) {
  const {
    currentPage,
    totalPages,
    isLoading,
    onNextPage,
    onPreviousPage,
    onPageChange,
  } = props;

  if (isLoading) {
    return;
  }

  const isClient = useIsClient();

  const [page, setPage] = useState(currentPage);

  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  function handleClickPrevious() {
    const newPage = page - 1;
    setPage(newPage);
    onPreviousPage();
  }

  function handleClickNext() {
    const newPage = page + 1;
    setPage(newPage);
    onNextPage();
  }

  const getPageNumbers = () => {
    const pageNumbers = [];
    let start = Math.max(1, currentPage - 2);
    let end = Math.min(totalPages, currentPage + 2);

    if (end - start + 1 < 5 && totalPages > 1) {
      if (start === 1) {
        end = Math.min(5, totalPages);
      } else if (end === totalPages) {
        start = Math.max(1, totalPages - 4);
      }
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    isClient &&
    totalPages >= 1 && (
      <Wrapper>
        <PageItemContainer>
          {hasPreviousPage && (
            <PageItem>
              <NavButton
                onClick={handleClickPrevious}
                disabled={isLoading || !hasPreviousPage}
              >
                <PreviousIcon />
              </NavButton>
            </PageItem>
          )}
          <HLetterIcon />
          {getPageNumbers().map((pageNum) => (
            <PageItem key={pageNum}>
              <PageButton
                onClick={() => {
                  setPage(pageNum);
                  onPageChange?.(pageNum);
                }}
                isActive={pageNum === page}
                disabled={isLoading}
              >
                <ILetterIcon />
                <TextPageColor isActive={pageNum === page}>
                  {pageNum}
                </TextPageColor>
              </PageButton>
            </PageItem>
          ))}

          <RLetterIcon />
          <ELetterIcon />
          <DLetterIcon />
          <LLetterIcon />
          <YLetterIcon />

          {hasNextPage && (
            <PageItem>
              <NavButton
                onClick={handleClickNext}
                disabled={isLoading || !hasNextPage}
              >
                <NextIcon />
              </NavButton>
            </PageItem>
          )}
        </PageItemContainer>
      </Wrapper>
    )
  );
}
